@font-face {
  font-family: 'SF Pro Text';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/SFProText-Regular.ttf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/SFProText-Medium.ttf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/SFProDisplay-Semibold.ttf');
}

@font-face {
  font-family: 'TT Commons';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/TTCommons-DemiBold.ttf');
}

@font-face {
  font-family: 'TT Commons';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/TTCommons-DemiBold.ttf');
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  padding: 0 24px;
}

.PanelHeader--android {
  .PanelHeader__content {
    padding-left: 35px;
  }
}


.PanelHeader__content {
  position: absolute;

  width: 100%;

  background-color: var(--background_content);
}

.PanelHeader__left {
  position: absolute;
  z-index: 10;
  left: 0;

  display: flex;
  padding-left: 12px !important;
  align-items: center;
  height: 100%;

  .Icon--28 {
    padding: 0 !important;
  }
}

.PanelHeader--ios {
  .PanelHeader {
    &__in {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: var(--safe-area-inset-top);
    }
  }
}

@media (min-width: 660px) {
  .Root {
    width: 660px !important;
    min-height: 700px;
  }
}

